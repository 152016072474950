body {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Spantaran';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Spantaran.ttf') format('truetype');
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Spantaran';
}

p, span {
    font-family: 'Roboto';
}

a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}